import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider, Subscribe } from "unstated";
import AppContainer from "./unstatedContainers/AppContainer";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Provider>
    <Subscribe to={[AppContainer]}>{(app) => <App app={app} />}</Subscribe>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
