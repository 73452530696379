export default {
  clientType: {
    ico: "Ico",
    exchange: "Exchange",
    signals: "Signals",
    arbitrage: "Arbitrage",
  },
  clientRoles: {
    user: "USER",
    admin: "ADMIN",
  },
};
